<script setup>
/**
 * Useful to display an image from a media model in a component.
 */
import { computed } from 'vue';

const props = defineProps({
    // Media model
    media: Object,
    // Set the sizes attribute on the img tag
    sizes: {
        type: [String, Object],
    },
    // Sets the image to a 1x1 transparent svg if the screen is wider than this, preventing the image from downloading
    maxWidth: String,
    // Sets the image to a 1x1 transparent svg if the screen is narrower than this, preventing the image from downloading
    minWidth: String,
    // Set the class attribute on the img tag
    classes: String,
    // Set the title attribute on the img tag overwriting the media model title
    title: String,
    // Set the alt attribute on the img tag overwriting the media model alt
    alt: String,
    // Set the loading attribute on the img tag, defaults to lazy
    eager: Boolean,
});

const alt = computed(() => props.alt || props.media.alt);

const sizes = computed(() => {
    if (typeof props.sizes === 'string') {
        return props.sizes;
    }

    if (typeof props.sizes === 'object') {
        return Object.entries(props.sizes)
            .map(([key, value]) => (key === 'default' ? value : `(max-width: ${key}) ${value}`))
            .join(', ');
    }

    return null;
});

const title = computed(() => props.title || props.media.title);
</script>

<template>
    <picture>
        <source
            v-if="minWidth"
            srcset="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            :media="`(max-width: ${minWidth})`"
        />
        <source
            v-if="maxWidth"
            srcset="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            :media="`(min-width: ${maxWidth})`"
        />

        <img
            :loading="eager ? 'eager' : 'lazy'"
            :src="media.src"
            :srcSet="media.src_set"
            :sizes="sizes"
            :alt="alt"
            :class="classes"
            :title="title"
            draggable="false"
        />
    </picture>
</template>
